import { classNames } from '../../utils/classNames';
import useMediaQuery from '../../hooks/useMediaQuery';
import { useAppContext } from '../../context/AppContextProvider';

import FormComponent from '../form/FormComponent';
import background from '../../assets/hero/welcome-gradient.webp';
import backgroundMobile from '../../assets/hero/welcome-gradient-mobile.webp';
import Navigation from '../navigation/Navigation';

const CustomizeExperienceCard = () => {
	const {
		setMobileNavigationOpen,
		navigationPath,
		contentList,
		setContentList,
		availableSectionsList,
		setAvailableSectionsList,
		setSectionLoading,
	} = useAppContext();

	const isMobile = useMediaQuery('(max-width: 575px)');

	return (
		<div
			className={classNames(
				'relative flex items-center',
				'max-w-[820px] w-full',
				'lg:max-w-[clamp(550px,55%,820px)]',
				'xl:max-w-[clamp(600px,60%,820px)]',
				'xxl:max-w-[clamp(600px,70%,820px)]',
				'px-4 sm:px-8 md:px-10 lg:px-12 xl:px-16',
				'py-4 sm:py-12 md:py-16',
				'transition duration-300 ease-in-out z-20',
				'mx-auto rounded-[32px] bg-white overflow-hidden'
			)}
		>
			<img
				src={isMobile ? backgroundMobile : background}
				alt='decoration'
				className={classNames(
					'w-full absolute h-full z-[19]',
					'left-1/2 -translate-x-1/2',
					'bottom-0 translate-y-[30%] sm:translate-y-[25%] xl:translate-y-[30%]'
				)}
			/>

			<div className='flex-1 flex flex-col items-center justify-between gap-4 xxs:gap-6 sm:gap-10 xl:gap-8'>
				<div
					className={classNames(
						'flex flex-col items-center max-w-[610px] w-full mt-4 sm:mt-0',
						'text-[87px] leading-[87px]'
					)}
				>
					<h1
						className={classNames(
							'font-extrabold self-start',
							'ml-5 xs:ml-10 xxs:ml-16 sm:ml-[70px] md:ml-10 lg:ml-5 xl:ml-0',
							'text-[47px] xs:text-[50px] xxs:text-[55px] sm:text-[65px] md:text-[75px] lg:text-[70px] xl:text-[87px]',
							'leading-[47px] xs:leading-[50px] xxs:leading-[55px] sm:leading-[65px] md:leading-[75px] lg:leading-[70px] xl:leading-[87px]'
						)}
					>
						AI-First
					</h1>

					<span
						className={classNames(
							'font-extralight self-end',
							'mr-5 xs:mr-10 xxs:mr-16 sm:ml-[70px] md:mr-10 lg:mr-5 xl:mr-0',
							'text-[47px] xs:text-[50px] xxs:text-[55px] sm:text-[65px] md:text-[75px] lg:text-[70px] xl:text-[87px]',
							'leading-[47px] xs:leading-[50px] xxs:leading-[55px] sm:leading-[65px] md:leading-[75px] lg:leading-[70px] xl:leading-[87px]'
						)}
					>
						Automation
					</span>
				</div>

				<p
					className={classNames(
						'max-w-[405px] w-full text-center',
						'text-[12px] sm:text-[14px] lg:text-[16px]',
						'leading-[19px] xl:leading-[24px] z-20'
					)}
				>
					Empowering Businesses with <span className='font-semibold'>AI-driven Automation and AI Employees</span> for
					the Exponential Growth
				</p>

				<FormComponent mode='card' className='mt-4 sm:mt-0' />

				<div className={classNames(isMobile ? '' : 'hidden', 'max-w-[315px] w-full mx-auto py-4 z-[100]')}>
					<Navigation
						mode='mobile'
						setMobileNavigationOpen={setMobileNavigationOpen}
						className='w-full grid grid-cols-4 gap-4'
						navigationPath={navigationPath}
						contentList={contentList}
						setContentList={setContentList}
						availableSectionsList={availableSectionsList}
						setAvailableSectionsList={setAvailableSectionsList}
						setSectionLoading={setSectionLoading}
					/>
				</div>
			</div>
		</div>
	);
};

export default CustomizeExperienceCard;
