import germanyImage from '../assets/partners/ibm-germany.webp';
import cognyAIImage from '../assets/partners/cogny-ai.webp';
import sortServeImage from '../assets/partners/sofrtserve.webp';
import infoPulseImage from '../assets/partners/info-pulse.webp';
import digitalAIIImage from '../assets/partners/digital-ai.webp';

export const partnersImages = [
	{ value: 'Germany', imageSource: germanyImage },
	{ value: 'Infopulse', imageSource: infoPulseImage },
	{ value: 'Softserve', imageSource: sortServeImage },
	{ value: 'Cognigy', imageSource: cognyAIImage },
	{ value: 'Digital', imageSource: digitalAIIImage },
];
