import MobileLogo from '../components/MobileLogo';
import bigBlueEclipse from '../assets/big-blue-eclipse.webp';
import CustomizeExperienceCard from '../components/template-cards/CustomizeExperienceCard';

const HomeSection = () => {
	return (
		<section className='relative w-full lg:my-11 mb-8 md:mb-[73px] flex flex-col items-center'>
			<MobileLogo className='my-[40px] lg:hidden' />

			<img
				src={bigBlueEclipse}
				alt='decoration'
				className='absolute -top-[50px] sm:-top-[100px] md:-top-[150px] lg:-top-[370px] xl:-top-[500px] blur-[50px] md:blur-[30px]'
			/>

			<CustomizeExperienceCard />
		</section>
	);
};

export default HomeSection;
